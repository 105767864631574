// Generated by Framer (6d82f59)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-OjJwL"

const variantClassNames = {wZAvJVH2W: "framer-v-xuo30f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({banner, height, id, link, width, ...props}) => { return {...props, ozvpMlCcg: banner ?? props.ozvpMlCcg, PF6FyqyJ4: link ?? props.PF6FyqyJ4} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;banner?: {src: string; srcSet?: string; alt?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ozvpMlCcg, PF6FyqyJ4, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wZAvJVH2W", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={PF6FyqyJ4} nodeId={"wZAvJVH2W"} openInNewTab={false}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), sizes: componentViewport?.width || "100vw", ...toResponsiveImage(ozvpMlCcg)}} className={`${cx(scopingClassNames, "framer-xuo30f", className, classNames)} framer-ztktp5`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wZAvJVH2W"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}}><motion.div className={"framer-117i7im"} data-framer-name={"container"} layoutDependency={layoutDependency} layoutId={"N6cpuxKAN"}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((((componentViewport?.y || 0) + (componentViewport?.height || 203)) - 180) + 78.5)), sizes: "30px", ...toResponsiveImage(ozvpMlCcg)}} className={"framer-k47t3m"} layoutDependency={layoutDependency} layoutId={"FuIDA7i5O"} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}}/></motion.div></Image></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OjJwL.framer-ztktp5, .framer-OjJwL .framer-ztktp5 { display: block; }", ".framer-OjJwL.framer-xuo30f { height: 203px; overflow: visible; position: relative; text-decoration: none; width: 312px; }", ".framer-OjJwL .framer-117i7im { align-content: center; align-items: center; bottom: -7px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 187px; justify-content: center; left: 8px; overflow: hidden; padding: 0px; position: absolute; width: 130px; }", ".framer-OjJwL .framer-k47t3m { flex: none; height: 30px; position: relative; width: 30px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OjJwL .framer-117i7im { gap: 0px; } .framer-OjJwL .framer-117i7im > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OjJwL .framer-117i7im > :first-child { margin-left: 0px; } .framer-OjJwL .framer-117i7im > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 203
 * @framerIntrinsicWidth 312
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ozvpMlCcg":"banner","PF6FyqyJ4":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerh1WhDd5eG: React.ComponentType<Props> = withCSS(Component, css, "framer-OjJwL") as typeof Component;
export default Framerh1WhDd5eG;

Framerh1WhDd5eG.displayName = "New teacher card";

Framerh1WhDd5eG.defaultProps = {height: 203, width: 312};

addPropertyControls(Framerh1WhDd5eG, {ozvpMlCcg: {title: "banner", type: ControlType.ResponsiveImage}, PF6FyqyJ4: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerh1WhDd5eG, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})